import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';

import Anchor from '../../atoms/anchor';
import Typography from '../../atoms/typography';
import { css } from '@emotion/core';

const DisabledLinkCss = (props) => css`
  &:hover {
    color: ${props.theme.color.white};
  }
`;

const Title = styled(Anchor)`
  color: ${(props) => props.theme.color.white};
  letter-spacing: 0.24em;
  margin-bottom: 4rem;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  min-height: 3.2rem;
  border-bottom: 1px solid ${(props) => props.theme.color.brand};

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    border-bottom: 1px solid ${(props) => props.theme.color.white};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    margin-bottom: 5rem;
    border-bottom: 1px solid ${(props) => props.theme.color.brand};
    text-align: left;
  }

  &:visited {
    color: ${(props) => props.theme.color.white};
  }

  &:hover {
    color: ${(props) => props.theme.color.brandLight};
  }

  ${(props) => (props.disabled ? DisabledLinkCss : '')}
`;

const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 0 50%;
  width: 50%;
  padding: 0 1rem 0 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    padding: 0 3.7rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    padding: 0 1rem 0 0;
  }

  ${Title} {
    margin: 0;
    padding: 2rem 0;

    @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
      padding: 0 0 5rem;
    }

    @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
      padding: 0;
      margin-bottom: 5rem;
    }
  }
`;

const Item = styled(Typography)`
  border-bottom: 1px solid ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  padding: 1rem 0;
  text-decoration: none;
  text-align: center;
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  justify-self: flex-end;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    padding: 2rem 0;
    line-height: ${(props) => props.theme.modularScale.base};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    text-align: left;
    border-bottom: 0 none;
    line-height: ${(props) => props.theme.modularScale.base};
    padding: 0 0 1.1rem;
  }

  &:hover {
    color: ${(props) => props.theme.color.brandLight};
  }
`;

const FooterNavigation = () => (
  <>
    <Navigation>
      <Title href="/faq" hed>
        Customer Care
      </Title>

      <Link href="/holiday-shipping" passHref>
        <Item element="a" like="dec-1">
          Holiday Shipping
        </Item>
      </Link>

      <Link href="/delivery" passHref>
        <Item element="a" like="dec-1">
          Shipping & Delivery
        </Item>
      </Link>

      <Link href="/faq" passHref>
        <Item element="a" like="dec-1">
          F.A.Q.s
        </Item>
      </Link>

      <Link href="/privacy" passHref>
        <Item element="a" like="dec-1">
          Privacy Policy
        </Item>
      </Link>

      <Link href="/terms" passHref>
        <Item element="a" like="dec-1">
          Terms & Conditions
        </Item>
      </Link>

      <Link href="/contact" passHref>
        <Item element="a" like="dec-1">
          Contact Us
        </Item>
      </Link>

      <Link href="/returns-guide" passHref>
        <Item element="a" like="dec-1">
          Returns Guide
        </Item>
      </Link>

      <Link href="/privacy#opt_out_marketing" passHref>
        <Item element="a" like="dec-1">
          Do Not Sell My Personal Information
        </Item>
      </Link>
    </Navigation>

    <Navigation>
      <Title hed disabled>
        Company
      </Title>

      <Link href="/careers" as="/careers" passHref>
        <Item element="a" like="dec-1">
          Careers
        </Item>
      </Link>

      <Link href="/partner" passHref>
        <Item element="a" like="dec-1">
          Partner With Us
        </Item>
      </Link>

      <Link href="/press" passHref>
        <Item element="a" like="dec-1">
          Press
        </Item>
      </Link>

      <Link href="/accessibility" passHref>
        <Item element="a" like="dec-1">
          Accessibility
        </Item>
      </Link>

      <Link href="/size-guide" passHref>
        <Item element="a" like="dec-1">
          Size Guide
        </Item>
      </Link>

      <Link href="/sitemap" passHref>
        <Item element="a" like="dec-1">
          Sitemap
        </Item>
      </Link>

      <Link href="/affiliate" passHref>
        <Item element="a" like="dec-1">
          Become a Brand Ambassador
        </Item>
      </Link>
    </Navigation>
  </>
);

export default FooterNavigation;
