import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Icons = styled.div`
  display: flex;
  justify-content: center;

  svg {
    align-self: start;
    fill: ${(props) => (props.inverted ? props.theme.color.white : props.theme.color.brand)};
    transition-duration: ${(props) => props.theme.animation.default};
    transition-property: fill;
    transition-timing-function: ${(props) => props.theme.animation.easeOutQuad};
    width: 100%;
  }
`;

const Icon = styled.a`
  display: flex;
  padding: 1rem 1rem 0;
  width: 4.4rem;
  cursor: pointer;

  :hover {
    svg {
      fill: ${(props) => (props.inverted ? props.theme.color.white : props.theme.color.brandLight)};
    }
  }
`;

const hrefs = {
  facebook: 'https://www.facebook.com/maisonetteworld',
  instagram: 'https://www.instagram.com/maisonetteworld',
  pinterest: 'https://www.pinterest.com/maisonetteworld',
  twitter: 'https://twitter.com/maisonetteworld'
};

const SocialMediaIcons = (props) => {
  const [icons, setIcons] = useState('div');

  const getIconComponent = async () => Promise.all(
    props.icons.map(async (i) => import(`../../atoms/icon-social-${i.icon}`).then((c) => c.default))
  ).then((res) => res);

  const handleClick = (e) => {
    const elem = e.target.closest('a');

    if (elem) {
      const callback = props.icons.find((i) => i.icon === elem.dataset.site)?.action;
      if (callback) callback();
    }
  };

  const buildIcons = () => {
    const iconSet = [];

    icons.forEach((C, i) => {
      const { icon } = props.icons[i];

      iconSet.push(
        <Icon
          href={hrefs[icon]}
          rel="noopener nofollow"
          data-site={icon}
          key={icon}
          target="_blank"
          aria-label={`Visit the Maisonette ${icon} page`}
        >
          <C title={icon} />
        </Icon>
      );
    });

    return iconSet;
  };

  useEffect(() => {
    const fetchData = async () => {
      const T = await getIconComponent();
      setIcons(T);
    };

    fetchData();
  }, []);

  return (
    <Icons inverted={props.inverted} onClick={handleClick} icons={icons}>
      { icons.constructor === Array ? buildIcons(props.icons) : '' }
    </Icons>
  );
};

SocialMediaIcons.defaultProps = {
  inverted: false
};

SocialMediaIcons.propTypes = {
  inverted: PropTypes.bool,
  icons: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
      ])
    )
  ).isRequired
};

export default memo(SocialMediaIcons);
