import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Link from '../../utils/link';

const HedStyles = (props) => css`
  font-size: ${props.theme.modularScale.small};
  font-family: ${props.theme.font.caption};
  letter-spacing: 0.25em;
  line-height: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  padding-bottom: 0.8rem;
  border-bottom: 0.2rem solid ${props.theme.color.brand};
`;

const StyledAnchor = styled.a`
  color: ${(props) => props.theme.color.brand};
  font-family: ${(props) => (props.uppercase ? props.theme.font.caption : 'inherit')};
  font-size: ${(props) => props.theme.modularScale.small};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : '')};
  letter-spacing: ${(props) => (props.uppercase ? '0.25em' : '')};

  :visited {
    color: ${(props) => (props.hed ? props.theme.color.brandLight : props.theme.color.brand)};
  }

  ${(props) => (props.hed ? HedStyles : '')}
`;

/**
 * Anchor component, for link wrappers
 * @param {function} props.onClick - The click handler
 * @param {any} props.children - The children to wrap
 * @param {string} props.className - The class name to apply
 * @param {boolean} props.disabled - Whether the link is disabled
 * @param {boolean} props.hed - Whether the link is a heading
 * @param {string} props.href - The href to link to
 * @param {boolean} props.prefetch - Whether to prefetch the link
 * @param {boolean} props.underline - Whether to underline the link
 * @param {boolean} props.uppercase - Whether to transform the text to uppercase
 * @returns {JSX.Element} The parsed/wrapped link
 */
const Anchor = (props) => {
  const { onClick, children, className, disabled, hed, href, prefetch, underline, uppercase } = props;

  return !disabled ? (
    <Link prefetch={prefetch} href={href} passHref>
      <StyledAnchor onClick={onClick} className={className} hed={hed} underline={underline} uppercase={uppercase}>
        {children}
      </StyledAnchor>
    </Link>
  ) : (
    <StyledAnchor as="span" className={className} hed={hed} underline={underline} uppercase={uppercase}>
      {children}
    </StyledAnchor>
  );
};

Anchor.defaultProps = {
  children: '',
  className: '',
  disabled: false,
  hed: false,
  href: null,
  prefetch: true,
  underline: false,
  uppercase: false,
  onClick: () => {}
};

Anchor.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hed: PropTypes.bool,
  href: PropTypes.string,
  prefetch: PropTypes.bool,
  underline: PropTypes.bool,
  uppercase: PropTypes.bool
};

export { HedStyles as hedStyles };

Anchor.whyDidYouRender = true;

export default Anchor;
