import React from 'react';
import styled from '@emotion/styled';

import { Content, navigationCSS } from '../../theme/page';

import Typography from '../../atoms/typography';
import FooterNavigation from '../../tissues/footer-navigation';
import FooterContact from '../../tissues/footer-contact';
import FooterSubscribe from '../../tissues/footer-subscribe';
import AppleAppStoreDownload from '../../molecules/apple-app-store-download';

const FooterContainer = styled.footer`
  background: ${(props) => props.theme.color.brand};
  padding-bottom: 7rem;
  padding-top: 5.5rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    padding-top: 2rem;
    padding-bottom: 4.5rem;
  }
`;

const FooterWrapper = styled(Content)`
  ${navigationCSS}
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    flex-direction: row;
  }
`;

const FooterColumn = styled.div`
  color: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 4rem;

    @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
      margin-bottom: 7rem;
    }
  }

  :nth-of-type(2) {
    border-top: 1px solid ${(props) => props.theme.color.white};
    flex-direction: row;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}) {
    :nth-of-type(2) {
      border-top: 0 none;
    }

    :nth-of-type(3) {
      width: 50%;
      margin: auto;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    :nth-of-type(3) {
      margin: 0;
      padding-right: 2rem;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    padding: 0 5rem;

    :nth-of-type(1) {
      border-left: 1px solid ${(props) => props.theme.color.white};
      border-right: 1px solid ${(props) => props.theme.color.white};
      margin-bottom: 0;
      order: 3;
      width: 26%;
      justify-content: ${(props) => !props.isContact ? 'space-between' : ''};
    }

    :nth-of-type(2) {
      border-top: 0 none;
      margin-bottom: 0;
      order: 2;
      width: 37%;
    }

    :nth-of-type(3) {
      margin-bottom: 0;
      order: 3;
      width: 37%;
    }
  }
`;

const FooterLegal = styled(Typography)`
  color: ${(props) => props.theme.color.white};
  font-size: 1.2rem;
  margin-top: ${({ theme }) => theme.modularScale.thirtyTwo};
  text-align: center;
  padding: 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.medium}) {
    margin-top: auto;
    text-align: right;
    padding-right: 3rem;
  }

  > sup {
    font-style: normal;
    vertical-align: super;
    font-size: smaller;
  }
`;

const Footer = () => (
  <FooterContainer>
    <FooterWrapper>
      <FooterColumn isContact>
        <FooterContact />
      </FooterColumn>

      <FooterColumn>
        <FooterNavigation />
      </FooterColumn>

      <FooterColumn>
        <FooterSubscribe />
        <AppleAppStoreDownload />
        <FooterLegal element="small" like="paragraph-3">
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Maisonette
          <sup>&reg;</sup>
          {' '}
          LLC
        </FooterLegal>
      </FooterColumn>
    </FooterWrapper>
  </FooterContainer>
);

export default Footer;
