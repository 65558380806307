import React from 'react';
import MailingListInput from '../../molecules/mailing-list-input';

const FooterSubscribe = () => (
  <>
    <MailingListInput />
  </>
);

export default FooterSubscribe;
